import { Grid, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import HoverImg from "./HoverImg"
import { Link } from "./Link"

export const TopBrands: React.FC = () => {
  const data = useStaticQuery(query)
  const brands = [
    {
      image: data.burberry,
      name: `Burberry`,
      path: `/marken/burberry/`,
    },
    {
      image: data.givenchy,
      name: `Givenchy`,
      path: `/marken/givenchy/`,
    },
    {
      image: data.dolceGabbana,
      name: `Dolce & Gabbana`,
      path: `/marken/dolce-gabbana/`,
    },
    {
      image: data.marcJacobs,
      name: `Marc Jacobs`,
      path: `/marken/marc-jacobs/`,
    },
    {
      image: data.valentino,
      name: `Valentino`,
      path: `/marken/valentino/`,
    },
    {
      image: data.prada,
      name: `Prada`,
      path: `/marken/prada/`,
    },
  ]
  return (
    <Grid container={true} spacing={4}>
      {brands.map((brand, index) => (
        <Grid item={true} key={index} xs={6} sm={4} md={2}>
          <Link aria-label={brand.name} href={brand.path} underline="none">
            <Typography variant="srOnly">{brand.name}</Typography>
            <HoverImg alt={brand.name} fluid={brand.image.childImageSharp.fluid} />
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

const query = graphql`
  query TopBrandsQuery {
    burberry: file(relativePath: { eq: "brands/burberry.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    givenchy: file(relativePath: { eq: "brands/givenchy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    dolceGabbana: file(relativePath: { eq: "brands/dolcegabbana.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    marcJacobs: file(relativePath: { eq: "brands/marcjacobs.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    prada: file(relativePath: { eq: "brands/prada.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    valentino: file(relativePath: { eq: "brands/valentino.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 450) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
