import { Box, Button, Hidden, makeStyles, Typography } from "@material-ui/core"
import { FluidObject } from "gatsby-image"
import React from "react"

import HoverImg from "./HoverImg"
import { Link } from "./Link"

const useStyles = makeStyles((theme) => ({
  overlay: {
    pointerEvents: "none", // Make the hover effect work again.
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    position: "absolute",
    textAlign: "center",
    whiteSpace: "nowrap",
    zIndex: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    background: "rgba(0, 0, 0, 0.25)",
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "2.25rem",
  },
  cta: {
    color: theme.palette.common.white,
  },
}))

export interface SingleColumnHeroProps {
  imgDesktop: FluidObject
  imgMobile: FluidObject
  href: string
  title: string
}

export const SingleColumnHero: React.FC<SingleColumnHeroProps> = (props) => {
  const { imgDesktop, imgMobile, href, title } = props
  const classes = useStyles(props)
  return (
    <Link href={href}>
      <Box position="relative">
        <Hidden implementation="css" only="xs">
          <HoverImg alt={title} fluid={imgDesktop} />
        </Hidden>
        <Hidden implementation="css" smUp={true}>
          <HoverImg alt={title} fluid={imgMobile} />
        </Hidden>
        <div className={classes.overlay}>
          <div>
            <Typography className={classes.title} align="center" component="div" variant="h1">
              {title}
            </Typography>
            <Button variant="text" size="large" className={classes.cta}>
              Shop now »
            </Button>
          </div>
        </div>
      </Box>
    </Link>
  )
}
