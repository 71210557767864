import { Grid, makeStyles } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { Button } from "gatsby-theme-material-ui"
import React from "react"

import HoverImg from "./HoverImg"
import { Link } from "./Link"

const useStyles = makeStyles((theme) => ({
  buttonLabel: {
    textTransform: "none",
  },
  buttonRoot: {
    borderRadius: 0,
    marginTop: theme.spacing(1),
  },
}))

export const TopCategories: React.FC = () => {
  const classes = useStyles()
  const data = useStaticQuery(query)
  const categories = [
    {
      image: data.shoulderBag,
      name: `Umhängetaschen`,
      path: `/umhaengetaschen/`,
    },
    {
      image: data.handleBag,
      name: `Henkeltaschen`,
      path: `/henkeltaschen/`,
    },
    {
      image: data.eveningBag,
      name: `Abendtaschen`,
      path: `/abendtaschen/`,
    },
    {
      image: data.handbag,
      name: `Handtaschen`,
      path: `/handtaschen/`,
    },
    {
      image: data.shopper,
      name: `Shopper`,
      path: `/shopper/`,
    },
    {
      image: data.suitcase,
      name: `Koffer`,
      path: `/koffer/`,
    },
    {
      image: data.satchel,
      name: `Schultertaschen`,
      path: `/schultertaschen/`,
    },
    {
      image: data.beltBag,
      name: `Gürteltaschen`,
      path: `/guerteltaschen/`,
    },
  ]
  return (
    <Grid container={true} spacing={4}>
      {categories.map((category, index) => (
        <Grid item={true} key={index} xs={6} sm={3}>
          <Link aria-label={category.name} href={category.path} underline="none">
            <HoverImg alt={category.name} fluid={category.image.childImageSharp.fluid} />
            <Button
              fullWidth={true}
              variant="outlined"
              classes={{
                label: classes.buttonLabel,
                root: classes.buttonRoot,
              }}
            >
              {category.name}
            </Button>
          </Link>
        </Grid>
      ))}
    </Grid>
  )
}

const query = graphql`
  query TopCategoriesQuery {
    handbag: file(relativePath: { eq: "categories/handbag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    beltBag: file(relativePath: { eq: "categories/belt-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    eveningBag: file(relativePath: { eq: "categories/evening-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    suitcase: file(relativePath: { eq: "categories/suitcase.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    satchel: file(relativePath: { eq: "categories/satchel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    shopper: file(relativePath: { eq: "categories/shopper.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    shoulderBag: file(relativePath: { eq: "categories/shoulder-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handleBag: file(relativePath: { eq: "categories/handle-bag.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, maxHeight: 300) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
