import { Typography } from "@material-ui/core"
import React from "react"

export interface Block {
  type: string
  value: string | JSX.Element
}

export interface SeoContentProps {
  blocks: Block[]
}

export const SeoContent: React.FC<SeoContentProps> = (props) => {
  const { blocks } = props
  return (
    <React.Fragment>
      {blocks.map(({ type, value }, key) => {
        if (type.startsWith("heading")) {
          const variant = `h${type.split("-")[1]}` as "h2" | "h3" | "h4"
          return (
            <Typography variant={variant} align="center" key={key}>
              {value}
            </Typography>
          )
        }
        if (type === "paragraph") {
          if (typeof value === "string") {
            return (
              <Typography paragraph={true} dangerouslySetInnerHTML={{ __html: value }} key={key} />
            )
          }
          return (
            <Typography paragraph={true} key={key}>
              {value}
            </Typography>
          )
        }
      })}
    </React.Fragment>
  )
}
