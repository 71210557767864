import { Grid, makeStyles, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import { Link } from "./Link"

const useStyles = makeStyles((theme) => ({
  shopList: {
    listStyle: "none",
    marginBottom: theme.spacing(2),
    padding: 0,
  },
  shopListItem: {},
  logo: {
    width: 200,
  },
}))

export const PartnerShops: React.FC = () => {
  const data = useStaticQuery(query)
  const partnerShops = [
    {
      name: "Breuninger",
      logo: data.breuninger,
      url: "https://www.awin1.com/cread.php?s=2193955&v=11504&q=340873&r=667803",
    },
    {
      name: "fashionette",
      logo: data.fashionette,
      url: "https://www.awin1.com/cread.php?s=2193955&v=11504&q=340873&r=667803",
    },
    {
      name: "Koffer24.de",
      logo: data.koffer24,
      url: "https://www.awin1.com/cread.php?s=2278343&v=11888&q=350093&r=667803",
    },
    {
      name: "mirapodo",
      logo: data.mirapodo,
      url: "https://www.awin1.com/cread.php?s=2336047&v=14503&q=356825&r=667803",
    },
    {
      name: "OTTO",
      logo: data.otto,
      url: "https://www.awin1.com/cread.php?s=2491454&v=14336&q=374238&r=667803",
    },
    // {
    //   name: "Peek & Cloppenburg",
    //   logo: data.peekCloppenburg,
    //   url: "https://www.awin1.com/cread.php?s=2401721&v=14716&q=364972&r=667803",
    // },
    {
      name: "Quelle",
      logo: data.quelle,
      url: "https://www.awin1.com/cread.php?s=2439002&v=14835&q=368430&r=667803",
    },
    // {
    //   name: "Taschenkaufhaus",
    //   logo: data.taschenkaufhaus,
    //   url: "https://www.awin1.com/cread.php?s=2173330&v=11926&q=338456&r=667803",
    // },
    {
      name: "Wardow",
      logo: data.wardow,
      url: "https://www.awin1.com/cread.php?s=2613240&v=19197&q=386588&r=667803",
    },
  ]
  const classes = useStyles()
  return (
    <React.Fragment>
      <Typography align="center" variant="h2">
        Unsere Partner-Shops
      </Typography>
      <Typography paragraph={true}>
        Wir finden für dich die besten Taschen-Angebote aus einer Vielfalt von bekannten
        Online-Shops. Zu unseren Partnern zählen die größten Fashion-Retailer im deutschsprachigen
        Raum. Entdecke bei buybags.de eine gigantische Auswahl von über 50,000 Taschen zu günstigen
        Preisen!
      </Typography>
      <Grid
        container={true}
        spacing={3}
        justify="center"
        alignItems="center"
        component="ul"
        className={classes.shopList}
      >
        {partnerShops.map((partnerShop) => (
          <Grid item={true} key={partnerShop.name} component="li" className={classes.shopListItem}>
            <Link href={partnerShop.url} rel="sponsored">
              <Typography variant="srOnly">{partnerShop.name}</Typography>
              <Img
                alt={partnerShop.name}
                className={classes.logo}
                fadeIn={false}
                fixed={partnerShop.logo.childImageSharp.fixed}
                imgStyle={{
                  objectFit: "none",
                  objectPosition: "50% 50%",
                }}
                title={partnerShop.name}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
      {/* <Typography paragraph={true} variant="body2" align="center">
        *Es gibt zwei unabhängige Unternehmen Peek &amp; Cloppenburg mit Hauptsitzen in Düsseldorf
        und Hamburg. Der hier beworbene Shop gehört zur Unternehmensgruppe der Peek &amp;
        Cloppenburg KG, Düsseldorf, deren Standorte Sie unter www.peek-cloppenburg.de finden.
      </Typography> */}
    </React.Fragment>
  )
}

const query = graphql`
  query PartnerShopsQuery {
    breuninger: file(relativePath: { eq: "logos/breuninger.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    fashionette: file(relativePath: { eq: "logos/fashionette.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    koffer24: file(relativePath: { eq: "logos/koffer24.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    mirapodo: file(relativePath: { eq: "logos/mirapodo.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    otto: file(relativePath: { eq: "logos/otto.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    peekCloppenburg: file(relativePath: { eq: "logos/peek-cloppenburg.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    quelle: file(relativePath: { eq: "logos/quelle.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    taschenkaufhaus: file(relativePath: { eq: "logos/taschenkaufhaus.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
    wardow: file(relativePath: { eq: "logos/wardow.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  }
`
