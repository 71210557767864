import { Box, Grid, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
import { graphql, PageProps } from "gatsby"
import { Button } from "gatsby-theme-material-ui"
import React from "react"
import { JsonLd } from "react-schemaorg"
import { Organization, SearchAction, WebSite, WithContext } from "schema-dts"

import HoverImg from "../components/HoverImg"
import { Layout } from "../components/Layout"
import { Link } from "../components/Link"
import { PartnerShops } from "../components/PartnerShops"
import { SeoContent } from "../components/SeoContent"
import { SingleColumnHero } from "../components/SingleColumnHero"
import { TopBrands } from "../components/TopBrands"
import { TopCategories } from "../components/TopCategories"
import { PageContext } from "../interfaces/pageContext"

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 0,
  },
  buttonLabel: {
    textTransform: "none",
  },
  buttonRoot: {
    borderRadius: 0,
    marginTop: theme.spacing(1),
  },
  overlay: {
    left: "50%",
    position: "absolute",
    textAlign: "center",
    top: "50%",
    transform: "translate(-50%, -50%)",
    whiteSpace: "nowrap",
    zIndex: 1,
  },
  sale: {
    color: theme.palette.secondary.main,
    fontSize: "10vw",
    marginBottom: 0,
    textTransform: "uppercase",
  },
  trend: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-end",

    "& h3": {
      flexGrow: 1,
    },
  },
  navList: {
    listStyleType: "none",

    "& li": {
      lineHeight: "2rem",
    },
  },
  topBrands: {
    columns: 2,
  },
  visuallyHidden: {
    clipPath: "inset(100%)",
    clip: "rect(1px, 1px, 1px, 1px)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    whiteSpace: "nowrap",
    width: 1,
  },
}))

export type IndexPageProps = PageProps<any, PageContext>

const IndexPage: React.FC<IndexPageProps> = (props) => {
  const classes = useStyles(props)
  const { data, location, pageContext } = props
  const { site } = data
  const { siteMetadata } = site
  const { siteUrl } = siteMetadata

  const seoContent = [
    { type: `heading-2`, value: `Entdecke jetzt bei buybags.de die aktuellsten Taschen!` },
    {
      type: `paragraph`,
      value: (
        <>
          buybags.de bietet eine große Auswahl an Designertaschen,{" "}
          <Link href="https://de.wikipedia.org/wiki/Rucksack">Rucksäcken</Link> und Koffern für jede
          Jahreszeit und jeden Anlass. Wir haben eine Vielfalt an Designertaschen anzubieten, von{" "}
          <Link href="https://www.guess.eu/de-de/homepage">Guess</Link>,{" "}
          <Link href="https://www.michaelkors.de/">Michael Kors</Link> und{" "}
          <Link href="https://de.tommy.com/">Tommy Hilfiger</Link> bis hin zu den luxuriösen Taschen
          von <Link href="https://www.chloe.com/de">Chloé</Link>,{" "}
          <Link href="https://www.balenciaga.com/de">Balenciaga</Link>,{" "}
          <Link href="https://www.alexandermcqueen.com/de-de/">Alexander McQUEEN</Link> und{" "}
          <Link href="https://www.dolcegabbana.com/de/">Dolce & Gabbana</Link> aus hochwertigen
          Materialien. Wir führen angesagte Handtaschen und bieten durch unsere vielen Partner eine
          große Auswahl an. Zu dieser Auswahl zählen zum Beispiel auch die Hobo-Bags.
        </>
      ),
    },
    { type: `heading-3`, value: `Wie finde ich die richtige Tasche für einen bestimmten Anlass?` },
    {
      type: `paragraph`,
      value: (
        <>
          Hobos sind bekannt als funktionale Begleiter für den Alltag. Sie sind praktisch und bieten
          zugleich viel Stauraum. Viel Platz haben auch die Shopper zu bieten. Ob beim Shopping oder
          im Supermarkt als <Link href="https://de.wikipedia.org/wiki/Tasche">Tasche</Link> für
          Lebensmittel - Shopper sind praktisch und handlich zu gleich. Die Umhängetaschen hingegen
          zählen zu den einfachsten Taschen. Die Crossbody Bag kann sehr simpel über die Schultern
          getragen werden oder quer über das Outfit. Die{" "}
          <Link href="https://www.marcjacobs.com/">Marc Jacobs</Link> Umhängetaschen sind dabei
          echte Trendsetter. Abends hingegen bevorzugen viele Damen die Clutches oder die Pochettes
          als Abendtaschen.
        </>
      ),
    },
    {
      type: `heading-3`,
      value: `Stilsicheres Auftreten egal ob beim Ausgehen im Club oder im Urlaub.`,
    },
    {
      type: `paragraph`,
      value: (
        <>
          Egal ob eine silberne{" "}
          <Link href="https://de.wikipedia.org/wiki/Clutch_(Handtasche)">Clutch</Link> oder eine
          goldene Clutch von <Link href="https://row.jimmychoo.com/de_DE/home">Jimmy Choo</Link> -
          mit dieser eleganten Abendtasche zieht man alle Blicke auf sich. Schwarze{" "}
          <Link href="https://de.wikipedia.org/wiki/Handtasche">Handtaschen</Link> sind klassisch
          und können mit jedem Look kombiniert werden. Passend zu der Handtasche, findest du bei
          buybags.de auch eine schöne{" "}
          <Link href="https://de.wikipedia.org/wiki/Geldbeutel">Geldbörse</Link>. Falls man doch
          einen Wochenend-Urlaub plant, haben wir die passende Reisetasche für dich.{" "}
          <Link href="https://de.wikipedia.org/wiki/Koffer">Koffer</Link> und{" "}
          <Link href="https://de.wikipedia.org/wiki/Trolley">Trolleys</Link> von den Marken{" "}
          <Link href="https://www.hartmannluggage.de/shop-de.htm">Hartmann</Link>,{" "}
          <Link href="https://www.brics.it/de-de">Bric’s</Link> und{" "}
          <Link href="https://www.samsonite.de/">Samsonite</Link> für einen längeren Urlaub in Dubai
          oder auf den Bahamas kannst du auch bei uns entdecken und bei unseren Partnern kaufen.
        </>
      ),
    },
  ]

  const topCategories = [
    { name: "Abendtaschen", page: "/abendtaschen/" },
    { name: "Beuteltaschen", page: "/beuteltaschen/" },
    { name: "Geldbörsen & Etuis", page: "/geldboersen-etuis/" },
    { name: "Gürteltaschen", page: "/guerteltaschen/" },
    { name: "Handtaschen", page: "/handtaschen/" },
    { name: "Henkeltaschen", page: "/henkeltaschen/" },
    { name: "Hobos", page: "/hobos/" },
    { name: "Koffer ", page: "/koffer/" },
    { name: "Kosmetiktaschen", page: "/kosmetiktaschen/" },
    { name: "Ledertaschen", page: "/ledertaschen/" },
    { name: "Reisetaschen", page: "/reisetaschen/" },
    { name: "Rucksäcke", page: "/rucksaecke/" },
    { name: "Schultertaschen", page: "/schultertaschen/" },
    { name: "Shopper", page: "/shopper/" },
    { name: "Sporttaschen", page: "/sporttaschen/" },
    { name: "Strandtaschen", page: "/strandtaschen/" },
    { name: "Totes", page: "/totes/" },
    { name: "Umhängetaschen", page: "/umhaengetaschen/" },
    { name: "Weekender", page: "/weekender/" },
  ]

  const topBrands = [
    { name: "abro", page: "/marken/abro/" },
    { name: "Adidas", page: "/marken/adidas/" },
    { name: "aigner", page: "/marken/aigner/" },
    { name: "Bench.", page: "/marken/bench/" },
    { name: "Bogner", page: "/marken/bogner/" },
    { name: "Boss", page: "/marken/boss/" },
    { name: "Bree", page: "/marken/bree/" },
    { name: "Calvin Klein", page: "/marken/calvin-klein/" },
    { name: "COCCINELLE", page: "/marken/coccinelle/" },
    { name: "Comma", page: "/marken/comma/" },
    { name: "DKNY", page: "/marken/dkny/" },
    { name: "ELLEME", page: "/marken/elleme/" },
    { name: "Fjällräven", page: "/marken/fjaellraeven/" },
    { name: "Fossil", page: "/marken/fossil/" },
    { name: "FURLA", page: "/marken/furla/" },
    { name: "Gabor", page: "/marken/gabor/" },
    { name: "GANNI", page: "/marken/ganni/" },
    { name: "Guess", page: "/marken/guess/" },
    { name: "INYATI", page: "/marken/inyati/" },
    { name: "Joop!", page: "/marken/joop/" },
    { name: "Karl Lagerfeld", page: "/marken/karl-lagerfeld/" },
    { name: "Kenzo", page: "/marken/kenzo/" },
    { name: "Kurt Geiger", page: "/marken/kurt-geiger/" },
    { name: "Lacoste", page: "/marken/lacoste/" },
    { name: "Lauren Ralph Lauren", page: "/marken/lauren-ralph-lauren/" },
    { name: "liebeskind berlin", page: "/marken/liebeskind-berlin/" },
    { name: "Liu Jo", page: "/marken/liu-jo/" },
    { name: "Longchamp", page: "/marken/longchamp/" },
    { name: "LOVE-MOSCHINO", page: "/marken/love-moschino/" },
    { name: "Marc Jacobs", page: "/marken/marc-jacobs/" },
    { name: "MARCCAIN", page: "/marken/marccain/" },
    { name: "Michael Kors", page: "/marken/michael-kors/" },
    { name: "New Balance", page: "/marken/new-balance/" },
    { name: "Nike", page: "/marken/nike/" },
    { name: "Radley London", page: "/marken/radley-london/" },
    { name: "STAUD", page: "/marken/staud/" },
    { name: "Ted Baker", page: "/marken/ted-baker/" },
    { name: "The Bridge", page: "/marken/the-bridge/" },
    { name: "Tommy Hilfiger", page: "/marken/tommy-hilfiger/" },
    { name: "TORY BURCH", page: "/marken/tory-burch/" },
    {
      name: "Valentino by Mario Valentino",
      page: "/marken/valentino-by-mario-valentino/",
    },
    { name: "Versace Jeans Couture", page: "/marken/versace-jeans-couture/" },
    { name: "Alle Marken", page: "/marken/" },
  ]

  const luxusBrands = [
    { name: "ALEXANDER McQUEEN", page: "/marken/alexander-mcqueen/" },
    { name: "Balenciaga", page: "/marken/balenciaga/" },
    { name: "BOYY", page: "/marken/boyy/" },
    { name: "Chloé", page: "/marken/chloe/" },
    { name: "Dolce&Gabbana", page: "/marken/dolce-und-gabbana/" },
    { name: "Jimmy Choo", page: "/marken/jimmy-choo/" },
    { name: "Loewe", page: "/marken/loewe/" },
    { name: "MCM", page: "/marken/mcm/" },
    { name: "Salvatore Ferragamo", page: "/marken/salvatore-ferragamo/" },
    { name: "TOD'S", page: "/marken/tods/" },
    { name: "Tom Ford ", page: "/marken/tom-ford/" },
    { name: "Versace", page: "/marken/versace/" },
    { name: "WANDLER", page: "/marken/wandler/" },
  ]

  const suitcaseBrands = [
    { name: "Bric’s", page: "/marken/brics/" },
    { name: "Hartmann", page: "/marken/hartmann/" },
    { name: "Tumi", page: "/marken/tumi/" },
    { name: "Samsonite", page: "/marken/samsonite/" },
  ]

  pageContext.title = "Designertaschen kaufen - Taschen Online Shop"

  type SearchActionWithQueryInput = SearchAction & {
    "query-input": string
  }

  const searchAction: SearchActionWithQueryInput = {
    "@type": "SearchAction",
    target: `${siteUrl}?q={search_term_string}`,
    "query-input": "required name=search_term_string",
  }

  const websiteSchema: WithContext<WebSite> = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: siteUrl,
    potentialAction: searchAction,
  }

  const organizationSchema: WithContext<Organization> = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: siteUrl,
    logo: `${siteUrl}/buybags-logo.png`,
    sameAs: [
      "https://www.facebook.com/buybagsDE/",
      "https://www.instagram.com/buybags.de/",
      "https://www.pinterest.com/buybagsDE/",
    ],
  }

  return (
    <React.Fragment>
      <JsonLd<WebSite> item={websiteSchema} />
      <JsonLd<Organization> item={organizationSchema} />
      <Layout location={location} pageContext={pageContext}>
        <Grid container={true} spacing={4} className={classes.container}>
          <Grid item={true} xs={12}>
            <SingleColumnHero
              imgDesktop={data.heroDesktop.childImageSharp.fluid}
              imgMobile={data.heroMobile.childImageSharp.fluid}
              href="/luxustaschen/"
              title="Luxushandtaschen"
            />
          </Grid>

          <Grid item={true} xs={12}>
            <Typography align="center" variant="h1">
              Designertaschen online kaufen
            </Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <TopCategories />
          </Grid>

          <Grid item={true} xs={12}>
            <Typography align="center" variant="h2">
              Top-Marken
            </Typography>
          </Grid>

          <Grid item={true} xs={12}>
            <TopBrands />
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={4} justify="center">
              <Grid item={true} xs={6} sm={3}>
                <Button
                  fullWidth={true}
                  variant="outlined"
                  to="/marken/"
                  classes={{
                    label: classes.buttonLabel,
                    root: classes.buttonRoot,
                  }}
                >
                  Zu allen Marken
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Link href={"/sale"}>
              <Box position="relative">
                <div className={classes.overlay}>
                  <Typography align="center" component="div" variant="h2" className={classes.sale}>
                    Sale
                  </Typography>
                  <Button color="secondary" variant="text" size="large">
                    Shop now »
                  </Button>
                </div>
                <HoverImg alt="Sale" fluid={data.sale.childImageSharp.fluid} />
              </Box>
            </Link>
          </Grid>

          <Grid item={true} xs={12}>
            <SeoContent blocks={seoContent} />
          </Grid>

          <Grid item={true} xs={12} sm={3}>
            <Typography align="center" variant="h3">
              Top-Kategorien
            </Typography>
            <ul className={classes.navList}>
              {topCategories.map((category) => (
                <li key={category.name}>
                  <Link href={category.page}>{category.name}</Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item={true} xs={12} sm={6}>
            <Typography align="center" variant="h3">
              Top-Marken
            </Typography>
            <ul className={clsx(classes.navList, classes.topBrands)}>
              {topBrands.map((brand) => (
                <li key={brand.name}>
                  <Link href={brand.page}>{brand.name}</Link>
                </li>
              ))}
            </ul>
          </Grid>
          <Grid item={true} xs={12} sm={3}>
            <Typography align="center" variant="h3">
              Luxus-Marken
            </Typography>
            <ul className={classes.navList}>
              {luxusBrands.map((brand) => (
                <li key={brand.name}>
                  <Link href={brand.page}>{brand.name}</Link>
                </li>
              ))}
            </ul>
            <Typography align="center" variant="h3">
              Koffer-Marken
            </Typography>
            <ul className={classes.navList}>
              {suitcaseBrands.map((brand) => (
                <li key={brand.name}>
                  <Link href={brand.page}>{brand.name}</Link>
                </li>
              ))}
            </ul>
          </Grid>

          <Grid item={true} xs={12}>
            <PartnerShops />
          </Grid>
        </Grid>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
    heroDesktop: file(relativePath: { eq: "hero-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1333, maxHeight: 500) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    heroMobile: file(relativePath: { eq: "hero-mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 325) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sale: file(relativePath: { eq: "sale.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1232, maxHeight: 345) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
